<template>
  <b-container>
    <b-row class="match-height">
      <b-col
        class="d-flex align-items-center justify-content-center"
        cols="12"
        md="4"
        lg="6"
      >
      </b-col>
      <b-col cols="12" md="8" lg="6">
        <b-card>
          <!-- Header -->

          <div v-if="step===1 && type==='product'" class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">Agregar producto</h5>
          </div>
          <div v-if="step===1 && type==='service'" class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">Agregar servicio</h5>
          </div>
          <!-- Form -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(handleContinue)"
              @reset.prevent="resetForm"
            >
              <!-- General product or service information -->
              <b-row v-if="step===1">
                <!-- Product Name, variant -->
                <b-row>
                  <b-col sm="auto">
                    <div class="d-flex align-items-center h-100">
                      <base-cropper
                        :model="productData"
                        @cropped-image="productData.logo = $event"
                      />
                    </div>
                  </b-col>
                  <b-col class="mr-1">
                    <!-- Product Name -->
                    <validation-provider
                      #default="validationContext"
                      name="name"
                      rules="required"
                    >
                      <b-form-group
                        :label="type==='product' ? 'Nombre del producto' : 'Nombre del servicio'"
                        label-for="name"
                      >
                        <b-form-input
                          id="name"
                          v-model="productData.name"
                          autofocus
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Galletas"
                        />
  
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    
                    <!-- Variant -->
                    <validation-provider
                      #default="validationContext"
                      name="Variedad"
                      :rules="type === 'product' ? required : null"
                    >
                      <b-form-group
                        v-if="type==='product'"
                        label="Variedad"
                        label-for="variant"
                      >
                        <b-form-input
                          id="variant"
                          v-model="productData.variant"
                          autofocus
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="300gr"
                        />
  
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
  
                <!-- SKU -->
                <b-row>
                  <b-col>
                    <validation-provider
                      #default="validationContext"
                      name="Código de barras"
                      rules=""
                    >
                      <b-form-group
                        v-if="type==='product'"
                        label="Código de barras"
                        label-for="barcode"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="productData.sku"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Opcional"
                          />
                          <b-input-group-append>
                            <b-button
                              class="mr-1"
                              variant="primary"
                              @click="$bvModal.show('add-product-sku')"
                            >
                              <i class="fas fa-qrcode" />
                            </b-button>
                            <b-modal id="add-product-sku">
                              <StreamBarcodeReader
                                @decode="onDecode"
                              />
                            </b-modal>
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Is product private? -->
                <b-row class="w-100 py-1">
                  <h6 class="w-100 ml-1">Visibilidad del producto</h6>
                  <b-col>
                   <b-button-group v-if="type==='product'" size="sm">
                     <b-button :variant="!is_private ? 'success' : 'outline-secondary'" @click="is_private = false">
                       Publico
                     </b-button>
                     <b-button :variant="is_private ? 'danger' : 'outline-secondary'" @click="is_private = true">
                       Privado
                     </b-button>
                   </b-button-group>
                  </b-col>
                </b-row>

                
                <!-- Categories -->
                <validation-provider
                  #default="validationContext"
                  name="Categorías (obligatorio)"
                  rules=""
                >
                  <b-form-group label="Categorías (obligatorio)">
                    <!-- prop `add-on - change` is needed to enable adding tags vie the `change` event -->
                    <b-form-tags
                      v-model="productData.categories_ids"
                      size="lg"
                      add-on-change
                      no-outer-focus
                    >
                      <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                        <ul
                          v-if="tags.length > 0"
                          class="list-inline d-inline-block mb-1"
                        >
                          <li
                            v-for="tag in tags"
                            :key="tag"
                            class="list-inline-item"
                          >
                            <b-form-tag
                              :title="tag"
                              :disabled="disabled"
                              variant="primary"
                              class="my-50 mr-25"
                              @remove="removeTag(tag)"
                            >
                              {{ getTagNameById(tag) }}
                            </b-form-tag>
                          </li>
                        </ul>
                        <b-form-select
                          v-bind="inputAttrs"
                          :disabled="disabled || availableOptions.length === 0"
                          :options="availableOptions"
                          text-field="name"
                          value-field="id"
                          v-on="inputHandlers"
                        >
                          <template v-slot:first>
                            <!-- This is required to prevent bugs with Safari -->
                            <option
                              disabled
                              value=""
                            >
                              Selecciona una categoría...
                            </option>
                          </template>
                        </b-form-select>
                      </template>
                    </b-form-tags>
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
  
                <!-- Product price and cost-->
                <b-row class="mt-2">
  
                  <!-- Product price -->
                  <b-col>
                    <validation-provider
                      #default="validationContext"
                      name="price"
                      rules="required"
                    >
                      <b-form-group
                        label="Precio"
                        label-for="price"
                      >
                        <b-input-group prepend="$">
                          <b-form-input
                            id="price"
                            v-model="unitPrice"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            size="lg"
                            type="number"
                            step="0.01"
                          />
    
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-input-group>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
  
                  <!-- product cost -->
                  <b-col>
                    <validation-provider
                      #default="validationContext"
                      name="cost"
                      :rules="type === 'product' ? required : null"
                    >
                      <b-form-group
                        v-if="type==='product'"
                        label="Costo"
                        label-for="cost"
                      >
                      <b-input-group prepend="$">
                        <b-form-input
                          id="cost"
                          v-model="unitCost"
                          autofocus
                          :state="getValidationState(validationContext)"
                          trim
                          size="lg"
                        />
  
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Service cost -->
                <b-row v-if="type==='service'">
                  <b-col cols="6">
                    <!-- Question on service cost -->

                      <b-form-group
                        label="¿Deseas agregar lo que te cuesta a ti tu servicio?"
                        label-for="has_service_cost"
                      >
                        <b-form-checkbox
                          id="has_service_cost"
                          v-model="has_service_cost"
                          class="custom-control-primary"
                          name="check-button"
                          switch
                          >
                          Sí
                        </b-form-checkbox>
                      </b-form-group>
                    <validation-provider
                      #default="validationContext"
                      name="Costo"
                      :rules="type === 'service' ? required : null"
                    >
                      <b-form-group
                        label="Costo"
                        label-for="service_cost"
                        v-if="has_service_cost"
                      >
                        <b-form-input
                          id="service_cost"
                          v-model="unitCost"
                          autofocus
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="0"
                          type="number"
                          size="lg"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-row>

              <!-- Weighted Question -->
              <b-row v-if="step === 2 && type==='product'" class="d-flex mt-2">
                <b-col>
                  <b-form-group
                    label="¿El producto se vende por peso?"
                    label-for="is-weighted"
                    label-size="lg"
                  >
                    <b-button-group id='is-weighted' v-model="selectedOptionForWeight" class="d-flex align-items-center">
                      <b-button :variant="selectedOptionForWeight ? 'success' : 'outline-success'"  @click="handleWeightButton(true)">Si</b-button>
                      <b-button :variant="selectedOptionForWeight===false ? 'danger' : 'outline-danger'"  @click="handleWeightButton(false)">No</b-button>
                    </b-button-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Edible Question -->
              <b-row v-if="step === 3 && type==='product'" class="d-flex mt-2">
                <b-col>
                  <b-form-group
                    label="¿El producto es comestible?"
                    label-for="is-edible"
                    label-size="lg"
                  >
                    <b-button-group id='is-edible' v-model="selectedOptionForEdible" class="d-flex align-items-center">
                      <b-button :variant="selectedOptionForEdible ? 'success' : 'outline-success'" @click="handleEdibleButton(true)">Si</b-button>
                      <b-button :variant="selectedOptionForEdible===false ? 'danger' : 'outline-danger'" @click="handleEdibleButton(false)">No</b-button>
                    </b-button-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="step === 3 && type==='product' && is_edible === '1'" class="d-flex mt-2">
                <b-col>
                  <validation-provider
                    #default="validationContext"
                    name="Información nutrimental"
                    rules="required"
                  >
                    <b-form-group
                      label="¿Cuántos sellos de advertencia alimentaria tiene?"
                      label-for="nutritional-info"
                    >
                      <b-form-input
                        id="nutritional-info"
                        v-model="count"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="0"
                        type="number"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- Variants Question -->
              <b-row v-if="step === 4" class="d-flex mt-2">
                <b-col>
                  <b-form-group
                    label="¿El producto o servicio tiene variantes que deseas combinar?"
                    label-for="has-variants"
                    label-size="lg"
                  >
                    <b-button-group id='has-variants' v-model="selectedOptionForVariant" class="d-flex align-items-center">
                      <b-button :variant="selectedOptionForVariant ? 'success' : 'outline-success'" @click="handleVariantButton(true)">Si</b-button>
                      <b-button :variant="selectedOptionForVariant===false ? 'danger' : 'outline-danger'" @click="handleVariantButton(false)">No</b-button>
                    </b-button-group>
                  </b-form-group>

                  <!-- Variants selection -->
                  <b-form-group v-if="has_variants === '1'" label="Variantes">
                    <b-form-tags
                      v-model="variants_ids"
                      size="lg"
                      add-on-change
                      no-outer-focus
                    >
                      <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                        <ul
                          v-if="tags.length > 0"
                          class="list-inline d-inline-block mb-1"
                        >
                          <li
                            v-for="tag in tags"
                            :key="tag"
                            class="list-inline-item"
                          >
                            <b-form-tag
                              :title="tag"
                              :disabled="disabled"
                              variant="primary"
                              class="my-50 mr-25"
                              @remove="removeTag(tag), handleRemoveTag()"
                            >
                              {{ getVariantTagNameById(tag) }}
                            </b-form-tag>
                          </li>
                        </ul>
                        <b-form-select
                          v-bind="inputAttrs"
                          :disabled="disabled || availableVariants.length === 0"
                          :options="availableVariants"
                          text-field="name"
                          value-field="id"
                          v-on="inputHandlers"
                          @change="resetVariantOptionTags"
                        >
                          <template v-slot:first>
                            <!-- This is required to prevent bugs with Safari -->
                            <option
                              disabled
                              value=""
                            >
                              Selecciona las variantes que deseas combinar...
                            </option>
                          </template>
                        </b-form-select>
                      </template>
                    </b-form-tags>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Variants options combinations -->
              <b-row v-if="step === 4 && has_variants === '1' && variants_ids.length >= 1" class="d-flex mt-2">
                <b-col>
                  <!-- Variants option selection -->
                  <b-form-group label="Opciones de variantes">
                    <b-form-tags
                      v-model="variant_option_ids"
                      size="lg"
                      add-on-change
                      no-outer-focus
                    >
                      <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                        <ul
                          v-if="tags.length > 0"
                          class="list-inline d-inline-block mb-1"
                        >
                          <li
                            v-for="tag in tags"
                            :key="tag"
                            class="list-inline-item"
                          >
                            <b-form-tag
                              :title="tag"
                              :disabled="disabled"
                              variant="primary"
                              class="my-50 mr-25"
                              @remove="removeTag(tag)"
                            >
                              {{ getVariantOptionTagNameById(tag) }}
                            </b-form-tag>
                          </li>
                        </ul>
                        <b-form-select
                          v-bind="inputAttrs"
                          :disabled="disabled || availableVariantOptions.length === 0"
                          :options="availableVariantOptions"
                          text-field="combination_name"
                          value-field="ids"
                          v-on="inputHandlers"
                        >
                          <template v-slot:first>
                            <!-- This is required to prevent bugs with Safari -->
                            <option
                              disabled
                              value=""
                            >
                              Selecciona las opciones deseadas...
                            </option>
                          </template>
                        </b-form-select>
                      </template>
                    </b-form-tags>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Inventory Question -->
              <b-row v-if="step === 5" class="d-flex mt-2">
                <b-col>
                  <b-form-group
                    label="¿Deseas manejar inventario para este producto o servicio?"
                    label-for="has-inventory"
                    label-size="lg"
                  >
                    <b-button-group id='has-inventory' v-model="selectedOptionForInventory" class="d-flex align-items-center">
                      <b-button :variant="selectedOptionForInventory ? 'success' : 'outline-success'" @click="handleInventoryButton(true)">Si</b-button>
                      <b-button :variant="selectedOptionForInventory===false ? 'danger' : 'outline-danger'" @click="handleInventoryButton(false)">No</b-button>
                    </b-button-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Inventory for variant options -->
              <b-row v-if="step === 5 && has_inventory==='1' && variant_option_ids.length>0" class="d-flex mt-2">
                <b-col>
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Ingresa el inventario para cada una de las opciones de variantes
                      </h5>
                    </b-col>
                  </b-row>
                  <div v-for="(option, index) in variant_option_ids" :key="index">
                    <b-row>
                      <b-col
                        size="3"
                        class="d-flex align-items-left"
                        cols="8"
                      >
                        <h6>
                          <b-badge
                            pill
                            :variant="'light-info'"
                            class="mt-1"
                          >
                            {{ getVariantOptionTagNameById(option) }}
                          </b-badge>
                        </h6>
                      </b-col>
                      <b-col
                        cols="4"
                      >
                        <div>
                          <b-form-input
                            :id="'variant-option-info-' + index"
                            v-model="variant_option_inventory(option).units"
                            autofocus
                            :state="true"
                            trim
                            placeholder="15"
                            size="lg"
                            type="number"
                            @input="updateVariantOptionInventory(option, $event)"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>

              <!-- Inventory product or service with no variants -->
              <b-row v-if="step === 5 && has_inventory==='1' && variant_option_ids.length===0" class="d-flex mt-2">
                <b-col>
                  <b-form-group
                    label="Ingresa el inventario para el producto"
                    label-for="unique-inventory"
                  >
                    <b-form-input
                      id="unique-inventory"
                      v-model="units"
                      autofocus
                      :state="true"
                      trim
                      placeholder="0"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Description Question -->
              <b-row v-if="step === 6">
                <b-col size="12">
                  <b-form-group
                    label="¿Deseas añadir una descripción?"
                    label-for="has-description"
                    label-size="lg"
                  >
                    <b-button-group id='has-description' v-model="selectedOptionForDescription" class="d-flex align-items-center">
                      <b-button :variant="selectedOptionForDescription ? 'success' : 'outline-success'" @click="handleDescriptionButton(true)">Si</b-button>
                      <b-button :variant="selectedOptionForDescription===false ? 'danger' : 'outline-danger'" @click="handleDescriptionButton(false)">No</b-button>
                    </b-button-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="step === 6 && has_description==='1'" class="d-flex mt-2">
                <b-col size="12">
                  <b-form-group
                    label="Escribe una descripción detallada..."
                    label-for="product_description"
                  >
                    <b-form-input
                      id="product_description"
                      v-model="product_description"
                      autofocus
                      trim
                      placeholder="Este producto es para..."
                      type="text"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Taxes Question -->
              <b-row v-if="step === 7">
                <b-col size="12">
                  <b-form-group
                    label="¿Facturas las ventas de este producto?"
                    label-for="has-tax"
                    label-size="lg"
                  >
                    <b-button-group id='has-tax' v-model="selectedOptionForHasTax" class="d-flex align-items-center">
                      <b-button :variant="selectedOptionForHasTax ? 'success' : 'outline-success'" @click="handleHasTaxButton(true)">Si</b-button>
                      <b-button :variant="selectedOptionForHasTax===false ? 'danger' : 'outline-danger'" @click="handleHasTaxButton(false)">No</b-button>
                    </b-button-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="step === 7 && type=='product'">
                <b-col sm="6">
                  <b-form-group
                    label="Unidad de medida"
                    label-for="unit-type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="UnitType"
                      rules="required"
                    >
                      <v-select
                        v-model="unitCode"
                        :options="unitCodeOptions"
                        :reduce="val => val.value"
                        label="name"
                        class="h-10"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Continue, Back, Cancel and Finish Buttons -->
              <div class="d-flex mt-2 mr-1">
                <b-button
                  v-if="!loading && step === 1"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  :disabled="loading"
                  class="mr-2"
                  :to="{ name: 'store-products', params: { id: $route.params.id } }"
                >
                  Cancelar
                </b-button>

                <b-button
                  v-if="!loading && step !== 1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  class="mr-2"
                  @click="handleBack"
                >
                  Regresar
                </b-button>

                <b-button
                  v-if="step === 1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  Continuar
                </b-button>

                <b-button
                  v-if="step === 2 && type==='product'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="is_weighted === '0'"
                >
                  Continuar
                </b-button>

                <b-button
                  v-if="step === 3 && type==='product'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="is_edible==='0'"
                >
                  Continuar
                </b-button>

                <b-button
                  v-if="step === 4"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="has_variants==='0' ? true : has_variants==='-1' ? false : variants_ids.length >= 1 ? false : true"
                >
                  Continuar
                </b-button>

                <b-button
                  v-if="step === 5"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="has_inventory === '0'"
                >
                  Continuar
                </b-button>

                <b-button
                  v-if="step === 6"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="has_description==='0'"
                >
                  Continuar
                </b-button>
               
                <b-button
                  v-if="step===7"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  @click="onSubmit"
                  :disabled = "has_tax_response==='0'"
                >
                  Finalizar
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTags,
  BFormTag,
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BContainer,
  BImg,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BaseCropper from '@/@core/components/BaseCropper.vue'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { retrieveColumnLayout } from 'echarts/lib/layout/barGrid'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTags,
    BFormTag,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BImg,
    BContainer,
    BFormCheckbox,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BaseCropper,
    StreamBarcodeReader,
    VueSlider,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      is_private: false,
      type: '',
      step: 1,
      count: 0,
      required,
      categories: [],
      variants_ids: [],
      variant_option_inventories: {},
      variant_option_ids: [], 
      variant_options_combinations: [],
      units: 0,
      unitPrice: null,
      unitCost: null,
      loading: false,
      has_inventory: '0',
      has_variants: '0',
      has_description: '0',
      has_tax_response: '0',
      product_description: '',
      is_weighted: '0',
      is_edible: '0',
      has_tax: false,
      has_service_cost: false,
      showDescription: false,
      showFinishButton: false,
      disableContinueButton: true,
      store_product_variants_ids: [],
      selectedOptionForDescription: null,
      selectedOptionForWeight: null,
      selectedOptionForEdible: null,
      selectedOptionForVariant: null,
      selectedOptionForInventory: null,
      selectedOptionForHasTax: null,
      store_products_to_create: [{  }],
      product_response_id: null,
      unitCode: null,
      fixBackButtonIssue: false,
      taxTypeOptions: [
        {
          name: 'IVA',
          value: 'IVA',
        },
        {
          name: 'IEPS',
          value: 'IEPS',
        },
      ],
      unitCodeOptions: [
        {
          name: 'Por pieza',
          value: 'H87',
        },
        {
          name: 'Por kilogramo',
          value: 'KGM',
        },
        {
          name: 'Por litro',
          value: 'LTR',
        },
        {
          name: 'Por gramo',
          value: 'GRM',
        },
        {
          name: 'Por miligramo',
          value: 'MGM',
        },
        {
          name: 'Por mililitro',
          value: 'MLT',
        },
        {
          name: 'Por tonelada de carga',
          value: 'A75',
        },
      ]
    }
  },

  computed: {
    ...mapGetters('variants', ['variants','selected_variants']),

    availableOptions() {
      // eslint-disable-next-line
      return this.categories.filter(opt => {
        return this.productData.categories_ids.indexOf(opt.id) === -1
      })
    },

    availableVariants() {
      // eslint-disable-next-line
      return this.variants.filter(opt => {
        return this.variants_ids.indexOf(opt.id) === -1
      })
    },

    availableVariantOptions() {
      return this.variant_options_combinations.filter(opt => {
        return this.variant_option_ids.indexOf(opt.ids) === -1
      })
    },

    variant_option_inventory() {
      return (optionId) => {
        return this.variant_option_inventories[optionId] || { units: null };
      };
    },

    translatedValue() {
      const letters = ['a', 'b', 'c', 'd', 'e'];
      const count = parseInt(this.count);

      if (!isNaN(count) && count >= 0 && count < letters.length) {
        return letters[count];
      } else {
        return 'a'; // Set a default value if the count is invalid
      }
    },
  },

  setup({ emit }) {
    const blankProductData = {
      name: '',
      sku: '',
      logo: null,
      nutritional_info: 'a',
      categories_ids: [],
      is_edible: false,
      is_weighted: false,
      product_type: 'product',
    }
    const productData = ref(JSON.parse(JSON.stringify(blankProductData)))
    const resetproductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetproductData)

    return {
      productData,
      refFormObserver,
      getValidationState,
      resetForm,
      emit,
    }
  },
  
  watch: {
    variants_ids() {
      const selectedVariants = this.variants.filter(variant => {
        return this.variants_ids.indexOf(variant.id) !== -1;
      });
      this.set_selected_variants(selectedVariants)
      this.setVariantOptions()
    },
  },

  mounted() {
    this.type=this.$route.params.type
    this.fetchCategories({
      by_active_status: true,
      meta: {
        pagination: {
          per_page: 10000,
        },
      },
    })
      .then(response => {
        this.categories = response.data
      })
      this.fetchVariants({
        by_active_status: 'active',
        meta: {
          pagination: {
            per_page: this.entriesPerPage,
          },
        },
    })
      .then(response => {
        this.loading=false
      })
  },

  methods: {
    ...mapActions('products', ['addProduct']),
    ...mapActions('storeProducts', ['addStoreProduct']),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('variants', ['fetchVariants']),
    ...mapMutations('variants', ['set_selected_variants','delete_selected_variants']),

    updateStoreProductsToCreate() {
      function store_products(variant_option_inventories, responseId, route, type, unitPrice, unitCost, variant_option_ids, has_service_cost, has_inventory,  product_description, units, is_private, has_tax_response, unitCode ) {
        if (variant_option_ids.length === 0) {
          return [{
              product_id: responseId, // replace response.id with the actual product ID
              store_id: route,
              is_private: is_private,
              unit_price: unitPrice,
              unit_cost: type==='product' ? unitCost : has_service_cost ? unitCost : 0,
              has_inventory: has_inventory === '1',
              has_tax: has_tax_response === '1' ? true : false,
              tax_rate: has_tax_response === '1' ? 0.16 : 0,
              is_tax_retained: false,
              tax_type: has_tax_response === '1' ? 'IVA' : '',
              unit_code: unitCode,
              price_includes_tax: has_tax_response === '1' ? true : false,
              inventory: units,
              description: product_description,
          }];
        } else {
          return variant_option_ids.map((optionId) => {
            const inventory = variant_option_inventories[optionId];
            
            const store_product_variants_attributes = optionId.split(';').filter(Boolean).map((id) => ({
              variant_option_id: id,
            }));

            if (inventory) {
              return {
                  product_id: responseId, // replace response.id with the actual product ID
                  store_id: route,
                  is_private: is_private,
                  unit_price: unitPrice,
                  unit_cost: type==='product' ? unitCost : has_service_cost ? unitCost : 0,
                  has_inventory: has_inventory === '1',
                  has_tax: has_tax_response === '1' ? true : false,
                  tax_rate: has_tax_response === '1' ? 0.16 : 0,
                  is_tax_retained: false,
                  tax_type: has_tax_response === '1' ? 'IVA' : '',
                  unit_code: unitCode,
                  price_includes_tax: has_tax_response === '1' ? true : false,
                  inventory: inventory.units,
                  description: product_description,
                  store_product_variants_attributes,
              };
            } else {
              // Handle the case where inventory for the optionId is not found
              return {
                  product_id: responseId, // replace response.id with the actual product ID
                  store_id: route,
                  is_private: is_private,
                  unit_price: unitPrice,
                  unit_cost: type==='product' ? unitCost : has_service_cost ? unitCost : 0,
                  has_inventory: false,
                  has_tax: has_tax_response === '1' ? true : false,
                  tax_rate: has_tax_response === '1' ? 0.16 : 0,
                  is_tax_retained: false,
                  tax_type: has_tax_response === '1' ? 'IVA' : '',
                  unit_code: unitCode,
                  price_includes_tax: has_tax_response === '1' ? true : false,
                  description: product_description,
                  store_product_variants_attributes,
              };
            }
          }).filter(Boolean); // Filter out null values
        }
      }
      this.store_products_to_create = store_products(this.variant_option_inventories, this.product_response_id, this.$route.params.id, this.type, this.unitPrice, this.unitCost, this.variant_option_ids, this.has_service_cost, this.has_inventory,  this.product_description, this.units, this.is_private, this.has_tax_response, this.unitCode);
    },

    updateVariantOptionInventory(optionId, value) {
      this.$set(this.variant_option_inventories, optionId, { units: value });
    },

    removeVariantOption(index) {
      this.variant_option_ids.splice(index, 1);
    },

    handleWeightButton(value) {
      this.selectedOptionForWeight = value
      this.is_weighted = value ? '1' : '-1'
    },

    handleEdibleButton(value) {
      this.selectedOptionForEdible = value
      this.is_edible = value ? '1' : '-1'
    },

    handleVariantButton(value) {
      this.selectedOptionForVariant = value
      this.has_variants = value ? '1' : '-1'
    },

    handleInventoryButton(value) {
      this.selectedOptionForInventory = value
      this.has_inventory = value ? '1' : '-1'
    },

    handleDescriptionButton(value) {
      this.selectedOptionForDescription = value
      this.has_description = value ? '1' : '-1'
    },

    handleHasTaxButton(value) {
      this.selectedOptionForHasTax = value
      this.has_tax_response = value ? '1' : '-1'
    },

    resetVariantOptionTags(){
      this.variant_option_ids = []
    },

    setOptionNames() {
      let option_names=[]
      this.selected_variants.forEach(variant=> {
        let options = []
        variant.variant_options_attributes.forEach(option => {
          options.push({id: option.id, option_name: option.option_name})
        })
        option_names.push(options)
      })
      return option_names
    },

    setVariantOptions() {
      function generateCombinations(arraysOfObjects, currentIndex = 0, currentCombination = '', currentIds = '', result = []) {
        if (currentIndex === arraysOfObjects.length) {
          result.push({ combination_name: currentCombination, ids: currentIds });
          return;
        }

        arraysOfObjects[currentIndex].forEach(option => {
          const updatedIds = currentIds ? `${currentIds};${option.id}` : option.id; // Update ids string
          generateCombinations(
            arraysOfObjects,
            currentIndex + 1,
            currentCombination.length ? `${currentCombination} - ${option.option_name}` : option.option_name,
            updatedIds,
            result
          );
        });
      }

      const result = [];
      const option_names = this.setOptionNames()      
      generateCombinations(option_names, 0, '', [], result);
      this.variant_options_combinations = result
    },

    handleRemoveTag() {
      this.variant_option_ids = []
    },
   
    handleContinue() {
      if (this.step === 1) {
        if ((Number(this.unitPrice) < Number(this.unitCost) && this.type === 'product') || (Number(this.unitPrice) < Number(this.unitCost) && this.type === 'service' && this.has_service_cost)) {
          this.$swal({
            title: 'ERROR!',
            text: 'El precio no puede ser menor al costo',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          return
        } else if (this.productData.categories_ids.length === 0) {
          this.$swal({
            title: 'ERROR!',
            text: 'Debes seleccionar al menos una categoría',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          return
        } 
        if (this.type === 'product') {
          this.step = 2;
        } else if (this.type === 'service' && this.fixBackButtonIssue == false) {
          this.step = 4;
        }
        this.fixBackButtonIssue=false
      } else if (this.step === 2) {
        this.step = 3;
      } else if (this.step === 3) {
        this.step = 4;
      } else if (this.step === 4) {
          this.step = 5;
      } else if (this.step === 5) {
        this.step = 6;
      } else if (this.step === 6) {
        this.step = 7;
      }
    },

    handleBack() {  
      if (this.step === 2) {
        this.step = 1;
      } else if (this.step === 3) {
        this.step = 2;
      } else if (this.step === 4 && this.type === 'product') {
        this.step = 3;
      } else if (this.step === 4 && this.type === 'service') {
        this.fixBackButtonIssue=true
        this.step = 1;
      } else if (this.step === 5) {
        this.step = 4;
      } else if (this.step === 6) {
        this.step = 5;
      } else if (this.step === 7) {
        this.step = 6;
      }
    },

    onDecode(result) {
      this.productData.sku = result
      this.$bvModal.hide('add-product-sku')
    },

    onSubmit() {
      this.unitCode = this.type == 'product' ? this.unitCode : 'E48'
      this.loading = true
      this.productData.nutritional_info = this.translatedValue
      this.productData.is_edible = this.is_edible === '1' ? true : false
      this.productData.is_weighted = this.is_weighted === '1' ? true : false
      this.productData.product_type = this.type
      this.addProduct(this.productData)
        .then(response => {
          this.product_response_id = response.id
          this.updateStoreProductsToCreate()
          this.store_products_to_create.forEach(storeProduct => {
            this.addStoreProduct(storeProduct)
              .then(() => {
                if(this.store_products_to_create.indexOf(storeProduct) === this.store_products_to_create.length - 1) {
                  this.$swal({
                    text: 'Producto(s) agregado exitosamente!',
                    title: 'Producto(s) creado!',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                  this.$router.push({ name: 'store-products', params: { id: this.$route.params.id } })
                  this.loading = false
                }
              })
              .catch(error => {
                this.loading = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.response.messages[0],
                  },
                })
              })
          })
        })
        .catch(error => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.response.messages.base[0],
            },
          })
        })
    },

    getTagNameById(id) {
      let name = ''
      this.categories.forEach(x => {
        if (x.id === id) {
          name = x.name
        }
      })
      return name
    },

    getVariantTagNameById(id) {
      let name = ''
      this.variants.forEach(x => {
        if (x.id === id) {
          name = x.name
        }
      })
      return name
    },

    getVariantOptionTagNameById(id) {
      let name = ''
      this.variant_options_combinations.forEach(x => {
        if (x.ids === id) {
          name = x.combination_name
        }
      })
      return name
    },

    handleOptionSelect(selectedOption) {
      this.variant_option_ids.push({ ids: selectedOption.id, units: null });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
